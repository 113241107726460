<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="6">
                    <b-form-group
                        :class="{ row, error: v$.type.$errors.length }"
                        :label="$t('forms.type')"
                        label-for="h-branch"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-type"
                            v-model="type"
                            :options="types"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.type.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.format.$errors.length }"
                        :label="$t('forms.format')"
                        label-for="h-branch"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-format"
                            v-model="format"
                            :options="formats"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.format.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.format.$errors.length }"
                        :label="$t('forms.date')"
                        label-for="h-branch"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="range"
                            v-model="range"
                            :enableTimePicker="false"
                            range
                            :max-date="new Date()"
                            format="dd.MM.yyyy"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.range.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";

export default {
    components: {
        vSelect,
        Datepicker,
    },
    props: {
        action: {type: String, default: null},
    },
    data() {
        return {
            type: null,
            format: null,
            range: null,
            types: [],
            formats: [],
        };
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(
            ["export_type", "export_format"],
            this.types,
            this.formats,
        );
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            type: {required},
            format: {required},
            range: {required},
        };
    },
    watch: {
        action() {
            if (this.action) {
                this.onSubmit();
            }
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                const body = {
                    type: this.type.id,
                    format: this.format.id,
                    fromDate: this.range[0],
                    toDate: this.range[1]
                };

                this.$Exports.createResource({body})
                    .then((response) => {
                        if (response.status === 200) {
                            const link = document.createElement("a");
                            link.href = response.data.url;
                            link.target = "_blank";
                            link.click();
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$helper.showToast(
                                useToast(),
                                Toast,
                                error.response.data["hydra:title"],
                                error.response.data["hydra:description"],
                                "danger"
                            );
                        }
                    });
            }
            this.$emit("clearAction");
        },
        success() {
            this.$router.push(`/export`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
